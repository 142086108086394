<template>
  <div
    class="container"
    v-title
    :data-title="$t('i18n.anInManage')"
    id="anInManage"
  >
    <div id="outer-title">{{ $t("i18n.anInManage") }}</div>
    <jl-form class="basic" :columns="columns" inline="true"></jl-form>
    <el-tabs v-model="activeName">
      <el-tab-pane :label="$t('i18n.insReport')" name="1">
        <jl-form
          class="insReport-form"
          :columns="insReportColumns"
          :option="btnOption"
        ></jl-form>
      </el-tab-pane>
      <el-tab-pane :label="$t('i18n.aiUpdate')" name="2">
        <jl-form
          class="insReport-form"
          :columns="aiUpdateColumns"
          @onSubmit="submitForm"
          @resetForm="onCancel"
        ></jl-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import jlForm from "../../components/form";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "AnnualInfocou",
  components: {
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const route = useRoute();
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      columns: [
        {
          prop: "number",
          label: "elevatorNo",
          type: "input",
          readonly: true,
        },
        {
          prop: "alias",
          label: "projectAlias",
          type: "input",
          readonly: true,
        },
        {
          prop: "projectName",
          label: "projectName",
          type: "input",
          readonly: true,
        },
        {
          prop: "yearCheckPerson",
          label: "man",
          type: "input",
          readonly: true,
        },
        {
          prop: "yearCheckPhone",
          label: "contractInformation",
          type: "input",
          readonly: true,
        },
        {
          prop: "annualValidity",
          label: "lastAnnualInspectionDate",
          type: "input",
          readonly: true,
        },
        {
          prop: "certificate",
          label: "certification",
          type: "upload",
          value: [],
          readonly: true,
        },
        {
          prop: "yearCheckStatusText",
          label: "aiStatus",
          type: "input",
          readonly: true,
        },
        {
          prop: "nextAnnualDate",
          label: "nextAnnualInspectionDate",
          type: "input",
          readonly: true,
        },
      ],
      activeName: "1",
      insReportColumns: [
        {
          prop: "inspectionYesText",
          label: "statusInfo",
          type: "input",
          readonly: true,
        },
        {
          prop: "inspectionReport",
          label: "insReport",
          type: "upload",
          value: [],
          readonly: true,
          show: false,
        },
        {
          prop: "inspectionReport",
          label: "update",
          type: "upload",
          action: "inspection",
          limit: "1",
          value: [],
          show: false,
          tip: "只能上传jpg/png/pdf文件",
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      btnOption: [
        {
          label: "update",
          class: "primary",
          show: false,
          method: (row) => {
            onSubmit(row);
          },
        },
      ],
      aiUpdateColumns: [
        {
          prop: "annualValidity",
          label: "lastAnnualInspectionDate",
          type: "date",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "yearCheckPerson",
          label: "man",
          type: "input",
        },
        {
          prop: "yearCheckPhone",
          label: "contractInformation",
          type: "input",
        },
        {
          prop: "certificate",
          label: "certification",
          type: "upload",
          action: "certificate",
          limit: "1",
          value: [],
          tip: "只能上传jpg/png/pdf文件",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "overdueReason",
          label: "overdueReason",
          type: "switch",
          text: { aText: "rectify", aVal: "1", iText: "other", iVal: "2" },
          show: false,
          value: "1",
          rules: [{ required: true, message: t("i18n.input") }],
          change: (val) => {
            state.aiUpdateColumns[5].show = val == "1";
          },
        },
        {
          prop: "rectifyReport",
          label: "rectify",
          type: "input",
          show: false,
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
    });

    const init = async () => {
      let id = route.query.id;
      let { data } = await proxy.$api.maintenance.getAnnualDetail(id);
      state.columns.map((item) => {
        item.value =
          item.type === "upload" ? setUploadData(data, item) : data[item.prop];
      });
      state.insReportColumns.map((item) => {
        if (item.label === "statusInfo") {
          // 状态信息
          item.value = data[item.prop];
        } else {
          // 更新附件 展示附件
          item.show =
            data.yearCheckStatus == "10" || data.yearCheckStatus == "15"
              ? true
              : false;
          state.btnOption[0].show = item.show;
          if (item.label === "insReport") {
            // 展示附件
            if (data[item.prop]) {
              item.value = setUploadData(data, item);
            } else {
              item.show = false;
            }
          }
        }
      });
      state.aiUpdateColumns.map((item) => {
        if (item.prop === "overdueReason" || item.prop === "rectifyReport") {
          item.isUpdate = true;
          item.show = data.yearCheckStatus == "20" ? true : false;
        }
      });
    };

    const setUploadData = (data, item) => {
      let files = [];
      if (data[item.prop]) {
        data[item.prop].split(",").map((f) => {
          let name = f.split("/")[f.split("/").length - 1];
          let obj = {
            name: name,
            url: f,
          };
          files.push(obj);
        });
      }
      return files;
    };

    const onSubmit = async (data) => {
      let row = JSON.parse(JSON.stringify(data));
      await proxy.$api.maintenance.updateInspectionReport({
        inspectionReport: row.inspectionReport,
        id: route.query.id,
      });
      init();
      proxy.$defined.tip(t("i18n.update") + t("i18n.success"), "success");
    };

    const submitForm = async (data) => {
      // 提交年检更新表单
      let row = JSON.parse(JSON.stringify(data));
      row.id = route.query.id;
      row.annualValidity = proxy.$defined.format(row.annualValidity);
      await proxy.$api.maintenance.yearUpdate(row);
      init();
      proxy.$defined.tip(t("i18n.update") + t("i18n.success"), "success");
    };

    const onCancel = () => {
      router.go(-1);
    };

    init();

    return {
      ...toRefs(state),
      onSubmit,
      submitForm,
      onCancel,
    };
  },
};
</script>

<style lang="scss">
#anInManage {
  .basic {
    .el-form-item__label {
      width: 100px;
    }
  }
  .insReport-form {
    width: 60%;
    .form-button {
      text-align: center;
      .el-form-item__content {
        margin: 0 !important;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .basic {
    .el-form-item .el-form-item__content input {
      width: 220px !important;
    }
  }
  #anInManage {
    .insReport-form {
      width: 100%;
    }
  }
}
</style>
